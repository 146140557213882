import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermsPage from "../../components/legal/terms-of-service"
const termsofservice = () => {
  return (
    <Layout>
      <SEO
        title="terms"
        desc="terms"
        pathname="/terms-of-service"
        keywords="kyts terms-of-service"
      />
      <TermsPage />
    </Layout>
  )
}

export default termsofservice
